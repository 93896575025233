export const moneyFormatter = new Intl.NumberFormat("tr-TR", {
  style: "currency",
  currency: "TRY",
  currencyDisplay: "symbol",
  currencySign: "accounting"
});
export const moneyFormatterUSD = new Intl.NumberFormat("tr-TR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  currencySign: "accounting"
});
export const moneyFormatterEUR = new Intl.NumberFormat("tr-TR", {
  style: "currency",
  currency: "EUR",
  currencyDisplay: "symbol",
  currencySign: "accounting"
});
