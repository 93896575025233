<template>
  <div>
    <div>
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="area"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { moneyFormatter } from "@/core/utils";
export default {
  name: "ProfitChart",
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    series: {
      type: Array,
      default: () => []
    },
    xAxis: {
      type: Array,
      required: true
    },
    options: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    chartOptions() {
      return {
        chart: {
          type: "area",
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {},
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [
            this.layoutConfig("colors.theme.base.success"),
            this.layoutConfig("colors.theme.base.info")
          ]
        },
        xaxis: {
          tickAmount: 6,
          categories: this.xAxis,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family")
            }
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: this.layoutConfig("colors.theme.base.info"),
              width: 1,
              dashArray: 3
            }
          }
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: this.layoutConfig("colors.gray.gray-500"),
                fontSize: "12px",
                fontFamily: this.layoutConfig("font-family")
              },
              formatter: function(val) {
                return moneyFormatter.format(val);
              }
            }
          }
        ],
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          },
          y: [
            {
              formatter: function(val) {
                return moneyFormatter.format(val);
              }
            },
            {
              formatter: function(val) {
                return moneyFormatter.format(val);
              }
            }
          ]
        },
        colors: [
          this.layoutConfig("colors.theme.light.success"),
          this.layoutConfig("colors.theme.light.info")
        ],
        grid: {
          borderColor: this.layoutConfig("colors.gray.gray-200"),
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          strokeColor: [
            this.layoutConfig("colors.theme.base.success"),
            this.layoutConfig("colors.theme.base.info")
          ],
          strokeWidth: 3
        }
      };
    }
  }
};
</script>

<style></style>
