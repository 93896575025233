<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Masraf Detayları</span
          >
          <span class="d-block text-muted pt-2 font-size-sm"
            >{{ dateRange }} tarihleri arasında</span
          >
        </h3>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="row">
        <div class="col-lg-4">
          <Expenses :expenses="expenses" />
        </div>
        <div class="col-lg-8">
          <ExpensesChart :series="series" :labels="labels" />
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Expenses from "./Expenses.vue";
import ExpensesChart from "./ExpensesChart.vue";
export default {
  name: "ExpensesChartWrapper",
  components: {
    Expenses,
    ExpensesChart
  },
  props: {
    expenses: {
      type: Array,
      default: () => []
    },
    dateRange: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error: {
        status: false,
        message: ""
      }
    };
  },
  computed: {
    series() {
      return [
        {
          name: "Masraf",
          data: this.expenses.map(el => parseFloat(el.amount))
        }
      ];
    },
    labels() {
      return this.expenses.map(el => el.expense);
    }
  }
};
</script>
