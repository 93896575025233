<template>
  <b-modal
    id="accountingReportFilterModal"
    ref="accountingReportFilterModal"
    no-close-on-backdrop
    no-fade
    centered
    header-class="py-3"
    footer-class="py-3"
    @cancel="handleModalCancel"
    @ok="handleModalOk"
  >
    <template #modal-header>
      <div
        class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
      >
        <div class="d-flex flex-column align-items-center">
          <h5>Filtrele</h5>
        </div>
      </div>
    </template>

    <div class="form-group">
      <!-- BEGIN: Theme -->
      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Tema</div>
        <div class="checkbox-list">
          <Checkbox
            v-for="item in themeList"
            :key="`theme__${item.key}`"
            :text="item.value"
            :value="item.key"
            :is-checked="reportingThemesFilter.includes(item.key)"
            @changed="onThemeCheckboxChange($event, item.key)"
          />
        </div>
      </div>
      <!-- END: Theme -->
      <!-- BEGIN: Service Types -->
      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">
          Hizmet Tipi
        </div>
        <div class="checkbox-list">
          <Checkbox
            v-for="item in serviceTypeList"
            :key="`service-type__${item.key}`"
            :text="item.value"
            :value="item.key"
            :is-checked="reportingServiceTypesFilter.includes(item.key)"
            @changed="onServiceTypeCheckboxChange($event, item.key)"
          />
        </div>
      </div>
      <!-- END: Service Types -->
    </div>

    <template #modal-footer="{ ok, cancel }">
      <div
        class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
      >
        <button
          type="button"
          class="btn btn-hover-transparent-dark font-weight-bolder py-4"
          @click="cancel"
        >
          Temizle
        </button>

        <button
          type="button"
          class="btn btn-dark font-weight-bolder px-5 py-4"
          @click="ok"
        >
          Uygula
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {
  UPDATE_REPORTING_THEMES_FILTER,
  UPDATE_REPORTING_SERVICE_TYPES_FILTER
} from "@/core/services/store/filter-reporting.module";
import Checkbox from "@/view/library/Checkbox";
export default {
  name: "AccountingReportFilter",
  components: {
    Checkbox
  },
  computed: {
    ...mapGetters([
      "experienceConfig",
      "reportingThemesFilter",
      "reportingServiceTypesFilter"
    ]),
    themeList() {
      return Object.entries(this.experienceConfig(`theme`)).map(
        ([key, value]) => {
          return {
            key,
            value: value.tr
          };
        }
      );
    },
    serviceTypeList() {
      return Object.entries(this.experienceConfig(`serviceType`)).map(
        ([key, value]) => {
          return {
            key,
            value: value.tr
          };
        }
      );
    }
  },
  data() {
    return {
      timeout: null
    };
  },
  methods: {
    onThemeCheckboxChange(isChecked, key) {
      if (isChecked) {
        this.$store.dispatch(UPDATE_REPORTING_THEMES_FILTER, [
          ...this.reportingThemesFilter,
          key
        ]);
      } else {
        this.$store.dispatch(
          UPDATE_REPORTING_THEMES_FILTER,
          this.reportingThemesFilter.filter(el => el !== key)
        );
      }
    },
    onServiceTypeCheckboxChange(isChecked, key) {
      if (isChecked) {
        this.$store.dispatch(UPDATE_REPORTING_SERVICE_TYPES_FILTER, [
          ...this.reportingServiceTypesFilter,
          key
        ]);
      } else {
        this.$store.dispatch(
          UPDATE_REPORTING_SERVICE_TYPES_FILTER,
          this.reportingServiceTypesFilter.filter(el => el !== key)
        );
      }
    },
    handleModalCancel() {
      this.$store.dispatch(UPDATE_REPORTING_THEMES_FILTER, []);
      this.$store.dispatch(UPDATE_REPORTING_SERVICE_TYPES_FILTER, []);
      this.$emit("applied");
    },
    handleModalOk() {
      this.$emit("applied");
    }
  }
};
</script>
