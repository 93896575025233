<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Satışların Para Birimine Göre Dağılımı</span
          >
          <span class="d-block text-muted pt-2 font-size-sm"
            >{{ dateRangeString }} tarihleri arasında</span
          >
        </h3>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <DistributionOfSalesByCurrencyChart
        :series="series"
        :labels="categories"
      />
      <div class="mt-10">
        <div class="row row-paddingless mb-10">
          <!--begin::Item-->
          <div class="col mb-5">
            <div class="d-flex align-items-center mr-2">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-info">
                    <span class="font-size-h4">₺</span>
                  </span>
                </div>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  {{ trySalesVolume }}
                </div>
                <div class="font-size-sm text-muted font-weight-bold mt-1">
                  Satış Hacmi
                </div>
              </div>
              <!--end::Title-->
            </div>
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="col mb-5">
            <div class="d-flex align-items-center mr-2">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-danger">
                    <span class="font-size-h4">$</span>
                  </span>
                </div>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  {{ usdSalesVolume }}
                </div>
                <div class="font-size-sm text-muted font-weight-bold mt-1">
                  Satış Hacmi
                </div>
              </div>
              <!--end::Title-->
            </div>
          </div>
          <!--end::Item-->
          <!--begin::Item-->
          <div class="col mb-5">
            <div class="d-flex align-items-center mr-2">
              <!--begin::Symbol-->
              <div
                class="symbol symbol-45 symbol-light-warning mr-4 flex-shrink-0"
              >
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-lg svg-icon-danger">
                    <span class="font-size-h4">€</span>
                  </span>
                </div>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div>
                <div class="font-size-h4 text-dark-75 font-weight-bolder">
                  {{ eurSalesVolume }}
                </div>
                <div class="font-size-sm text-muted font-weight-bold mt-1">
                  Satış Hacmi
                </div>
              </div>
              <!--end::Title-->
            </div>
          </div>
          <!--end::Item-->
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  moneyFormatter,
  moneyFormatterUSD,
  moneyFormatterEUR
} from "@/core/utils";
import ApiService from "@/core/services/api.service";
import DistributionOfSalesByCurrencyChart from "./DistributionOfSalesByCurrencyChart.vue";
export default {
  name: "DistributionOfSalesByCurrency",
  components: { DistributionOfSalesByCurrencyChart },
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: [],
      series: [],
      categories: ["TRY", "USD", "EUR"],
      error: {
        status: false,
        message: ""
      }
    };
  },
  computed: {
    ...mapGetters(["reportingThemesFilter", "reportingServiceTypesFilter"]),
    dateRangeString() {
      return `${moment(this.startDate).format("DD MMM YYYY")} - ${moment(
        this.endDate
      ).format("DD MMM YYYY")}`;
    },
    trySalesVolume() {
      const currency = this.data.find(el => el.currency === "TRY");
      if (currency) {
        return moneyFormatter.format(currency.salesAmount);
      }
      return "-";
    },
    usdSalesVolume() {
      const currency = this.data.find(el => el.currency === "USD");
      if (currency) {
        return moneyFormatterUSD.format(currency.salesAmount);
      }
      return "-";
    },
    eurSalesVolume() {
      const currency = this.data.find(el => el.currency === "EUR");
      if (currency) {
        return moneyFormatterEUR.format(currency.salesAmount);
      }
      return "-";
    }
  },
  methods: {
    fetchData() {
      ApiService.get(
        `accounting/reporting/distribution-of-sales-by-currency/get.req.php?start=${
          this.startDate
        }&end=${
          this.endDate
        }&theme=${this.reportingThemesFilter.join()}&serviceType=${this.reportingServiceTypesFilter.join()}`
      )
        .then(({ data }) => {
          this.series = [
            {
              name: "Rezervasyon",
              data: data.data.map(el => el.salesNumber)
            }
          ];
          this.data = data.data;
          this.error.status = false;
        })
        .catch(({ response }) => {
          this.error.message = response.data.errorMessage;
          this.error.status = true;
        });
    }
  },
  watch: {
    dateRangeString() {
      this.fetchData();
    }
  },
  async created() {
    await this.fetchData();
  }
};
</script>
