<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Gelir Grafiği</span
          >
        </h3>
      </div>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark" role="tablist">
          <li class="nav-item">
            <button
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'monthly' }"
              @click="show = 'monthly'"
            >
              <span class="nav-text font-weight-bolder font-size-sm"
                >Aylık</span
              >
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'weekly' }"
              @click="show = 'weekly'"
            >
              <span class="nav-text font-weight-bolder font-size-sm"
                >Haftalık</span
              >
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'daily' }"
              @click="show = 'daily'"
            >
              <span class="nav-text font-weight-bolder font-size-sm"
                >Günlük</span
              >
            </button>
          </li>
        </ul>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <ProfitChart
        :start-date="startDate"
        :end-date="endDate"
        :series="series"
        :x-axis="categories"
      />
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import ProfitChart from "./ProfitChart.vue";
export default {
  name: "ProfitChartWrapper",
  components: {
    ProfitChart
  },
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: "daily",
      series: [],
      categories: [],
      error: {
        status: false,
        message: ""
      }
    };
  },
  computed: {
    ...mapGetters(["reportingThemesFilter", "reportingServiceTypesFilter"]),
    dateRangeString() {
      return `${this.startDate}-${this.endDate}`;
    }
  },
  methods: {
    fetchData() {
      if (this.show === "monthly") {
        this.getMonthlyData();
      } else if (this.show === "weekly") {
        this.getWeeklyData();
      } else if (this.show === "daily") {
        this.getDailyData();
      }
    },
    getMonthlyData() {
      ApiService.get(
        `accounting/reporting/profit/monthly/get.req.php?start=${
          this.startDate
        }&end=${
          this.endDate
        }&theme=${this.reportingThemesFilter.join()}&serviceType=${this.reportingServiceTypesFilter.join()}`
      )
        .then(({ data }) => {
          this.series = [
            {
              name: "Gelir",
              data: data.data.profits.map(el => el.profit)
            },
            {
              name: "Brüt",
              data: data.data.incomes.map(el => el.income)
            }
          ];
          this.categories = data.data.profits.map(el => el.month);
          this.$emit("totalNet", data.data.metadata.totalProfit);
          this.$emit("totalGross", data.data.metadata.totalIncome);
          this.$emit(
            "profitabilityRatio",
            data.data.metadata.profitabilityRatio
          );
          this.$emit("totalPax", data.data.metadata.totalPax);
          this.error.status = false;
        })
        .catch(({ response }) => {
          this.error.message = response.data.errorMessage;
          this.error.status = true;
        });
    },
    getWeeklyData() {
      ApiService.get(
        `accounting/reporting/profit/weekly/get.req.php?start=${
          this.startDate
        }&end=${
          this.endDate
        }&theme=${this.reportingThemesFilter.join()}&serviceType=${this.reportingServiceTypesFilter.join()}`
      )
        .then(({ data }) => {
          this.series = [
            {
              name: "Gelir",
              data: data.data.profits.map(el => el.profit)
            },
            {
              name: "Brüt",
              data: data.data.incomes.map(el => el.income)
            }
          ];
          this.categories = data.data.profits.map(el => el.week);
          this.$emit("totalNet", data.data.metadata.totalProfit);
          this.$emit("totalGross", data.data.metadata.totalIncome);
          this.$emit(
            "profitabilityRatio",
            data.data.metadata.profitabilityRatio
          );
          this.$emit("totalPax", data.data.metadata.totalPax);
          this.error.status = false;
        })
        .catch(({ response }) => {
          this.error.message = response.data.errorMessage;
          this.error.status = true;
        });
    },
    getDailyData() {
      ApiService.get(
        `accounting/reporting/profit/daily/get.req.php?start=${
          this.startDate
        }&end=${
          this.endDate
        }&theme=${this.reportingThemesFilter.join()}&serviceType=${this.reportingServiceTypesFilter.join()}`
      )
        .then(({ data }) => {
          this.series = [
            {
              name: "Gelir",
              data: data.data.profits.map(el => el.profit)
            },
            {
              name: "Brüt",
              data: data.data.incomes.map(el => el.income)
            }
          ];
          this.categories = data.data.profits.map(
            el => `${el.day} ${el.month}`
          );
          this.$emit("totalNet", data.data.metadata.totalProfit);
          this.$emit("totalGross", data.data.metadata.totalIncome);
          this.$emit(
            "profitabilityRatio",
            data.data.metadata.profitabilityRatio
          );
          this.$emit("totalPax", data.data.metadata.totalPax);
          this.error.status = false;
        })
        .catch(({ response }) => {
          this.error.message = response.data.errorMessage;
          this.error.status = true;
        });
    }
  },
  watch: {
    show() {
      this.fetchData();
    },
    dateRangeString() {
      this.fetchData();
    }
  },
  async created() {
    await this.fetchData();
  }
};
</script>
