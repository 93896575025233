<template>
  <div class="d-flex flex-row flex-wrap align-items-center">
    <Chip
      v-for="theme in reportingThemesFilter"
      :key="theme"
      title="Tema"
      :value="experienceConfig(`theme`)[theme].tr"
      @close="removeTheme(theme)"
    />
    <Chip
      v-for="serviceType in reportingServiceTypesFilter"
      :key="serviceType"
      title="Hizmet Tipi"
      :value="experienceConfig(`serviceType`)[serviceType].tr"
      @close="removeServiceType(serviceType)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_REPORTING_THEMES_FILTER,
  UPDATE_REPORTING_SERVICE_TYPES_FILTER
} from "@/core/services/store/filter-reporting.module";
import Chip from "@/view/library/Chip";

export default {
  name: "AppliedReportingFilters",
  components: {
    Chip
  },
  computed: {
    ...mapGetters([
      "experienceConfig",
      "reportingThemesFilter",
      "reportingServiceTypesFilter"
    ])
  },
  methods: {
    removeTheme(theme) {
      this.$store.dispatch(
        UPDATE_REPORTING_THEMES_FILTER,
        this.reportingThemesFilter.filter(el => el !== theme)
      );
      this.$emit("changed");
    },
    removeServiceType(serviceType) {
      this.$store.dispatch(
        UPDATE_REPORTING_SERVICE_TYPES_FILTER,
        this.reportingServiceTypesFilter.filter(el => el !== serviceType)
      );
      this.$emit("changed");
    }
  }
};
</script>
