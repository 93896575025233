<template>
  <table class="table table-borderless table-vertical-center">
    <tbody>
      <tr v-for="(item, index) in formattedExpenses" :key="index">
        <td class="pl-0">
          <span class="text-dark font-weight-bolder font-size-lg">{{
            item.expense
          }}</span>
        </td>
        <td></td>
        <td class="text-right">
          <span class="text-primary font-weight-bolder d-block font-size-lg">{{
            item.amount
          }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { moneyFormatter } from "@/core/utils";
export default {
  props: {
    expenses: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    formattedExpenses() {
      return this.expenses.map(el => {
        return {
          ...el,
          amount: moneyFormatter.format(el.amount)
        };
      });
    }
  }
};
</script>
