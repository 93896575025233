<template>
  <div>
    <filter-modal @applied="onFilterApplied" />
    <div class="row gutter-b">
      <div class="col-lg-4">
        <div
          class="text-muted d-flex flex-row justify-content-between font-weight-bold font-size-md mb-4"
        >
          Tarih aralığı seçin
          <button
            class="text-danger mr-2"
            v-b-modal.accountingReportFilterModal
          >
            <span class="svg-icon svg-icon-danger svg-icon-md mr-2">
              <inline-svg src="media/svg/icons/Shopping/Settings.svg" /> </span
            >Filtre
          </button>
        </div>
        <DateRangePicker
          is-inline
          :initial-date-range="{ start: startDate, end: endDate }"
          @changed="onDateRangeChanged"
        />
      </div>
    </div>
    <AppliedFilters @changed="onAppliedFiltersChanged" />
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div
          class="card card-custom bg-light-white gutter-b"
          style="height: 100px;"
        >
          <div
            class="card-body d-flex flex-row p-0 align-items-center"
            style="position: relative;"
          >
            <div class="flex-grow-1 card-spacer-x">
              <div class="text-muted font-weight-bold font-size-lg">
                Brüt
              </div>
              <div class="font-weight-bolder font-size-h3 pt-2">
                {{ totalGross }}
              </div>
            </div>
            <div class="pr-6">
              <div class="symbol symbol-light-info mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-info svg-icon-xl">
                    <inline-svg src="media/svg/icons/Shopping/Wallet.svg" />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div
          class="card card-custom bg-light-white gutter-b"
          style="height: 100px;"
        >
          <div
            class="card-body d-flex flex-row p-0 align-items-center"
            style="position: relative;"
          >
            <div class="flex-grow-1 card-spacer-x">
              <div class="text-muted font-weight-bold font-size-lg">
                Gelir
              </div>
              <div class="font-weight-bolder font-size-h3 pt-2">
                {{ totalNet }}
              </div>
            </div>
            <div class="pr-6">
              <div class="symbol symbol-light-success mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-success svg-icon-xl">
                    <inline-svg src="media/svg/icons/Files/Download.svg" />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div
          class="card card-custom bg-light-white gutter-b"
          style="height: 100px;"
        >
          <div
            class="card-body d-flex flex-row p-0 align-items-center"
            style="position: relative;"
          >
            <div class="flex-grow-1 card-spacer-x">
              <div class="text-muted font-weight-bold font-size-lg">
                Kârlılık
              </div>
              <div class="font-weight-bolder font-size-h3 pt-2">
                {{ profitabilityRatio }}
              </div>
            </div>
            <div class="pr-6">
              <div class="symbol symbol-light-warning mr-3">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-warning svg-icon-xl">
                    <inline-svg src="media/svg/icons/Shopping/Sale1.svg" />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div
          class="card card-custom bg-light-white gutter-b"
          style="height: 100px;"
        >
          <div
            class="card-body d-flex flex-row p-0 align-items-center"
            style="position: relative;"
          >
            <div class="flex-grow-1 card-spacer-x">
              <div class="text-muted font-weight-bold font-size-lg">
                Pax
              </div>
              <div class="font-weight-bolder font-size-h3 pt-2">
                {{ parseInt(totalPax.online) + parseInt(totalPax.offline) }}
                <span class=" font-size-sm">Pax</span>
              </div>
            </div>
            <div class="flex-row">
              <div class="flex-grow-1 card-spacer-x">
                <div
                  class="text-success text-right font-weight-bolder font-size-h5 pt-2"
                >
                  {{ totalPax.online }}
                  <span class="text-success font-size-sm">Online</span>
                </div>
              </div>
              <div class="flex-grow-1 card-spacer-x">
                <div
                  class="text-warning text-right font-weight-bolder font-size-h5 pt-2"
                >
                  {{ totalPax.offline }}
                  <span class="text-warning font-size-sm">Offline</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProfitChartWrapper
      ref="profitChartWrapper"
      :start-date="startDate"
      :end-date="endDate"
      @totalNet="onTotalNetFetch"
      @totalGross="onTotalGrossFetch"
      @profitabilityRatio="onProfitabilityRatioFetch"
      @totalPax="onTotalPaxFetch"
    />
    <div class="separator separator-dashed gutter-b"></div>
    <div class="row">
      <div class="col-lg-12">
        <ExpensesChartWrapper
          :expenses="expenses"
          :date-range="dateRangeString"
        />
      </div>
      <div class="col-lg-6">
        <DistributionOfSalesByCurrency
          ref="distributionOfSalesByCurrency"
          :start-date="startDate"
          :end-date="endDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { moneyFormatter } from "@/core/utils";
import ApiService from "@/core/services/api.service";
import DateRangePicker from "@/view/library/DateRangePicker.vue";
import ProfitChartWrapper from "./components/ProfitChartWrapper.vue";
import ExpensesChartWrapper from "./components/ExpensesChartWrapper.vue";
import FilterModal from "./components/FilterModal.vue";
import AppliedFilters from "./components/AppliedFilters.vue";
import DistributionOfSalesByCurrency from "./components/DistributionOfSalesByCurrency.vue";
export default {
  components: {
    ExpensesChartWrapper,
    ProfitChartWrapper,
    DateRangePicker,
    FilterModal,
    AppliedFilters,
    DistributionOfSalesByCurrency
  },
  data() {
    return {
      startDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      totalNet: "",
      totalGross: "",
      profitabilityRatio: "",
      totalPax: {
        online: 0,
        offline: 0
      },
      expenses: [],
      incomes: [],
      error: {
        status: false,
        message: ""
      }
    };
  },
  computed: {
    ...mapGetters(["reportingThemesFilter", "reportingServiceTypesFilter"]),
    dateRangeString() {
      return `${moment(this.startDate).format("DD MMM YYYY")} - ${moment(
        this.endDate
      ).format("DD MMM YYYY")}`;
    }
  },
  methods: {
    onDateRangeChanged(range) {
      this.startDate = range.start;
      this.endDate = range.end;
      this.fetchExpenseItems();
      this.fetchIncomeItems();
    },
    onTotalNetFetch(val) {
      this.totalNet = moneyFormatter.format(val);
    },
    onTotalGrossFetch(val) {
      this.totalGross = moneyFormatter.format(val);
    },
    onProfitabilityRatioFetch(val) {
      this.profitabilityRatio = `% ${val}`;
    },
    onTotalPaxFetch(val) {
      this.totalPax = val;
    },
    fetchExpenseItems() {
      ApiService.get(
        `accounting/reporting/items/expense/get.req.php?start=${
          this.startDate
        }&end=${
          this.endDate
        }&theme=${this.reportingThemesFilter.join()}&serviceType=${this.reportingServiceTypesFilter.join()}`
      )
        .then(({ data }) => {
          this.expenses = data.expenses;
        })
        .catch(() => {});
    },
    fetchIncomeItems() {
      ApiService.get(
        `accounting/reporting/items/income/get.req.php?start=${
          this.startDate
        }&end=${
          this.endDate
        }&theme=${this.reportingThemesFilter.join()}&serviceType=${this.reportingServiceTypesFilter.join()}`
      )
        .then(({ data }) => {
          this.incomes = data.incomes.map(el => {
            return {
              ...el,
              amount: moneyFormatter.format(el.amount)
            };
          });
        })
        .catch(() => {});
    },
    onFilterApplied() {
      this.$refs["profitChartWrapper"].fetchData();
      this.$refs["distributionOfSalesByCurrency"].fetchData();
      this.fetchExpenseItems();
      this.fetchIncomeItems();
    },
    onAppliedFiltersChanged() {
      this.$refs["profitChartWrapper"].fetchData();
      this.$refs["distributionOfSalesByCurrency"].fetchData();
      this.fetchExpenseItems();
      this.fetchIncomeItems();
    }
  },
  async created() {
    moment.locale("tr");
  }
};
</script>
