<template>
  <apexchart
    class="card-rounded-bottom"
    :options="chartOptions"
    :series="series"
    type="bar"
  ></apexchart>
</template>

<script>
import { mapGetters } from "vuex";
import { moneyFormatter } from "@/core/utils";
export default {
  name: "ExpensesChart",
  props: {
    series: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ["20%"],
            endingShape: "rounded"
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: this.labels,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: this.layoutConfig("colors.gray.gray-500"),
                fontSize: "12px",
                fontFamily: this.layoutConfig("font-family")
              }
            }
          }
        ],
        fill: {
          opacity: 1
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          },
          y: [
            {
              formatter: function(val) {
                return moneyFormatter.format(val);
              }
            }
          ]
        },
        colors: [this.layoutConfig("colors.theme.base.success")],
        grid: {
          borderColor: this.layoutConfig("colors.gray.gray-200"),
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        }
      };
    }
  }
};
</script>
